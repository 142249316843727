<template>
    <!--Toast Alert Component is imported here-->
    <ToastNotifications ref="toastNotificationsRef" />
  <div class="card mb-4">
    <h2 class="text-center mt-3">Failed History</h2>

    <div class="card-body failed">
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          v-model="searchTerm"
          placeholder="Search by Mobile,ID or Amount"
        />
      </div>
      <table class="table mt-3 table-responsive-sm">
        <thead>
          <tr>
            <th>id</th>
            <th>Mobile</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(failed, index) in paginatedFailedDetails"
            v-bind:key="index"
          >
            <td>{{ failed.id }}</td>
            <td>{{ failed.MSISDN }}</td>
            <td>{{ failed.TransAmount }}</td>
            <td>{{ failed.created_at }}</td>
            <td>
              <span class="badge bg-danger"> {{ failed.Status }} </span>
            </td>
            <th>
              <form
                @submit.prevent="postData(failed.MSISDN, failed.created_at,failed.TransAmount)"
              >
                <button type="submit" class="btn resend btn-warning">
                  Resend
                </button>
              </form>
            </th>
          </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-between">
        <button
          class="btn btn-nav"
          @click="currentPage--"
          :disabled="currentPage === 1"
        >
          Previous
        </button>
        <span
          >{{ currentPage }} of
          {{ Math.ceil(filteredFailedDetails.length / itemsPerPage) }}</span
        >
        <button
          class="btn btn-nav"
          @click="currentPage++"
          :disabled="
            currentPage ===
            Math.ceil(filteredFailedDetails.length / itemsPerPage)
          "
        >
          Next
        </button>
      </div>
    </div>
  </div>

  <!-- <notifications position="top center"/> -->
</template>


<script>
import axios from "axios";

import ToastNotifications from "@/components/ToastNotifications.vue";
export default {
  components: { ToastNotifications },
  computed: {
    filteredFailedDetails() {

    const filtered = this.failedDetails.filter(failed => {
      return failed.MSISDN.includes(this.searchTerm) || failed.TransAmount.toString().includes(this.searchTerm);
      
    });
    return filtered.slice().reverse();
  },

  paginatedFailedDetails() {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.filteredFailedDetails.slice(start, end);
  }
  },

  data() {
    return {
      failedDetails: [],
      jdata: null,
      searchTerm: "",
      currentPage: 1,
      itemsPerPage: 15,
    };
  },

  created() {
    this.failedData();
  },

  methods: {
    async failedData() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}failed`)
        .then((response) => {
          this.failedDetails = response.data;
          this.successToast('Fetch Successful')
        })

        .catch((error) => {
          this.errorToast(error)
        });
    },

    async postData(MSISDN, created_at,amount) {
      await axios
        .post(`${process.env.VUE_APP_BASE_URL}query`, {
          MSISDN: MSISDN,
          dateCreated: created_at,
          amount: amount
        })

        .then((response) => {

          this.jdata = response.data;
          this.successToast(response.data.message)
        })
        .catch((error) => {
          this.errorToast(error.response.data.error)
          
        });
    },

     //success toast notification
     successToast(message) {
      message = String(message);
      this.$refs.toastNotificationsRef.triggerToast("success", message);
    },

    //error toast notification
    errorToast(message) {
      message = String(message); //convert the message to string
      this.$refs.toastNotificationsRef.triggerToast("error", message);
    },
  },
};
</script>

<style scoped>
.failed {
  overflow-y: auto;
  max-width: 100%;
}

h2,
th {
  font-family: "Roboto Mono", monospace;
}

.btn-nav {
  background-color: #E2725B;
  color: white;
}

.btn.btn-nav:hover {
  transform: scale(1);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

th,
td {
  font-size: smaller;
}
.resend {
  padding: 5px 20px;
  font-size: smaller;
  font-weight: bold;
}

.btn.resend:hover {
  color: #E2725B;
}
</style>
