<template>

    <!--Toast Alert Component is imported here-->
    <ToastNotifications ref="toastNotificationsRef" />
  <div class="">
    
    <div class="center-items mt-3">
      <Balance :countDetails="countDetails" />
    </div>
    <div class="center-items mt-3">
      <Chart />
    </div>
  </div>
</template>

<script>
import Balance from "../components/Balance.vue";
import Chart from "../components/Chart.vue";
import axios from "axios";
import ToastNotifications from "@/components/ToastNotifications.vue";

export default {
  components: {
    Balance,
    Chart,
    ToastNotifications,
  },
  data() {
    return {
      countDetails: [],
    };
  },

  created() {
    this.countData();
  },

  methods: {
    async countData() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}count`)
        .then((response) => {
          this.countDetails = response.data;
          this.successToast('Fetch successful');
        })
        .catch((error) => {
          this.errorToast(error.message);
        });
    },

     //success toast notification
     successToast(message) {
      message = String(message);
      this.$refs.toastNotificationsRef.triggerToast("success", message);
    },

    //error toast notification
    errorToast(message) {
      message = String(message); //convert the message to string
      this.$refs.toastNotificationsRef.triggerToast("error", message);
    },


  },
};
</script>

<style scoped>
@import "../assets/styles.css";
</style>
