<template>
  <!--Toast Alert Component is imported here-->
  <ToastNotifications ref="toastNotificationsRef" />
  <div class="container">
    <div class="card shadow mt-5">
      <div class="card-header">
        <h1 class="text-center mt-5">Data Bundles</h1>
      </div>
      <div class="card-body">
        <form
          @submit.prevent="submitFile"
          class="d-flex justify-content-center align-items-center"
          enctype="multipart/form-data"
          ref="uploadForm"
        >
          <input type="file" name="file" id="file" />
          <button class="btn btn-outline-secondary">Upload</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ToastNotifications from "@/components/ToastNotifications.vue";
export default {
  components: { ToastNotifications },
  methods: {
    submitFile() {
      let fileInput = document.getElementById("file");

      let formData = new FormData();

      if (fileInput.files.length > 0) {
        formData.append("file", fileInput.files[0]);
      }

      axios
        .post(`${process.env.VUE_APP_BASE_URL}uploadFile`, formData)
        .then((response) => {
         
            this.successToast("File uploaded successfully");
            this.$refs.uploadForm.reset();
         
        })
        .catch((error) => {
          this.errorToast(error.response.data.error.file);
        
        });
    },

    //success toast notification
    successToast(message) {
      message = String(message);
      this.$refs.toastNotificationsRef.triggerToast("success", message);
    },

    //error toast notification
    errorToast(message) {
      message = String(message); //convert the message to string
      this.$refs.toastNotificationsRef.triggerToast("error", message);
    },
  },
};
</script>

<style scoped>
.bod {
  border: 2px solid green;
}
</style>
