<template>
  <div class="balance-container">
    <div class="container ">
      <div class="row">
       
        <div class="col">
          <div class="card p-2 m-1 center-items text-white ">
            <div class="card-icon-top-left">
             <font-awesome-icon icon="fa-solid fa-sack-dollar" class="icon" />
            </div>
            <div class="card-body card-content mt-1">
              <h6 class="card-title text-center">Airtime Balance</h6>
              <p class="card-text text-center font-style ">{{bal}} KES</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card  p-2 m-1 center-items text-white">

            <div class="card-icon-top-left">
              <font-awesome-icon icon="fa-solid fa-chart-simple" class="icon" />
            </div>
            <div class="card-body card-content mt-1">
              <h6 class="card-title text-center">Total Transcations</h6>
              <p class="card-text text-center font-style">{{count}} Transactions</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {

  props:['countDetails'],


  data() {
    return {
      bal: null,
      count:null
    };
  },

  created() {
    this.balance();
    this.countTrans();
  },

  methods: {
    async balance() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}airtime-balance`)
        .then((response) => {
          this.bal = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

  async countTrans() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}count`)
        .then((response) => {
          this.count = response.data.count;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};


</script>

<style scoped>
@import "../assets/styles.css";

.card-content{
    overflow: auto;
    width: 200px; 
    height: 90px;
    
}

.card{
  background-color:#E2725B;
  position: relative;
  transition: transform 0.2s ease-in-out;
}

.card:hover {
  transform: scale(1.1);
}

.font-style{
  font-weight: 700;
}


.card-icon-top-left {
  position: absolute;
  top: 10px;
  left: 10px;
}

.icon{
  height: 20px;
}

</style>
