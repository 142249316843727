<template>
  <!--Toast Alert Component is imported here-->
  <ToastNotifications ref="toastNotificationsRef" />
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-6 mb-5">
        <div class="card shadow p-5">
          <div class="">
            <h3 class="text-center mt-2">Create Tips</h3>
          </div>
          <div class="card-body">
            <form @submit.prevent="createTip">
              <div class="form-group">
                <label for="startDate">Start Date</label>
                <input
                  type="datetime-local"
                  class="form-control mb-2"
                  v-model="startDate"
                  required
                />
              </div>
              <div class="form-group">
                <label for="endDate">End Date</label>
                <input
                  type="datetime-local"
                  class="form-control mb-2"
                  v-model="endDate"
                  required
                />
              </div>
              <div class="form-group">
                <label for="cost">Cost</label>
                <input
                  type="number"
                  class="form-control mb-2"
                  v-model="cost"
                  required
                />
              </div>
              <div class="form-group">
                <label for="shortcode">Shortcode</label>
                <select
                  class="form-control mb-2"
                  v-model="selectedShortcode"
                  required
                >
                  <option value="" disabled>Select Shortcode...</option>
                  <option
                    v-for="shortcode in shortcodes"
                    :key="shortcode.id"
                    :value="shortcode.id"
                  >
                    {{ shortcode.value }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="type">Type</label>
                <select
                  class="form-control mb-2"
                  v-model="selectedType"
                  required
                >
                  <option value="" disabled>Select Category...</option>
                  <option
                    v-for="tipType in tipTypes"
                    :key="tipType.id"
                    :value="tipType.category"
                  >
                    {{ tipType.category }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <label for="tip">Tip</label>
                <textarea
                  class="form-control"
                  v-model="tipContent"
                  required
                ></textarea>
              </div>
              <button type="submit" class="btn btn-custom mt-3">
                Create Tip
              </button>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card shadow mt-3">
          <div class="card-header">
            <h3 class="text-center mt-2">Active Tips</h3>
          </div>

          <div class="card-body">
            <ul class="list-group">
              <li
                v-for="tips in validTips"
                :key="tips.id"
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                <span>{{ tips.tip }}</span>
                <button
                  class="btn btn-outline-danger btn-sm"
                  @click="deleteTip(tips.id)"
                >
                  Delete
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-md-6">
        <div class="card shadow mb-3">
          <div class="card-header">
            <h3 class="text-center mt-2">Business Shortcodes</h3>
            <div class="col-auto">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="shortcodeInput"
                  placeholder="Enter Shortcode"
                />
                <div class="input-group-append">
                  <button class="btn btn-custom mx-1" @click="addShortcode">
                    + Add Shortcode
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li
                v-for="shortcode in shortcodes"
                :key="shortcode.id"
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                <span>{{ shortcode.value }}</span>
                <button
                  class="btn btn-outline-danger btn-sm"
                  @click="deleteShortcode(shortcode.id)"
                >
                  Delete
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card shadow mb-3">
          <div class="card-header">
            <h3 class="text-center mt-2">Tip Categories</h3>
            <div class="col-auto">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="tipCategory"
                  placeholder="Enter Category"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-custom float-right mx-1"
                    @click="addTipType"
                  >
                    + Add Category
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <ul class="list-group">
              <li
                v-for="tipType in tipTypes"
                :key="tipType.id"
                class="list-group-item d-flex justify-content-between align-items-center"
              >
                <span>{{ tipType.category }}</span>

                <button
                  class="btn btn-outline-danger btn-sm"
                  @click="deleteTipType(tipType.id)"
                >
                  Delete
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ToastNotifications from "@/components/ToastNotifications.vue";

export default {
  components: { ToastNotifications },
  data() {
    return {
      shortcodes: [],
      tipTypes: [],
      validTips: [],
      shortcodeInput: "",
      tipCategory: "",
      tipContent: "",
      startDate: "",
      endDate: "",
      cost: null,
      selectedShortcode: "",
      selectedType: "",
    };
  },
  mounted() {
    this.fetchShortcodes();
    this.fetchTipTypes();
    this.fetchValidTips();
  },
  methods: {
    async fetchShortcodes() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}shortcodes`
        );
        this.shortcodes = response.data.data;
        this.successToast("Shortcodes fetched succesfully")
      } catch (error) {
        this.errorToast( error.message);
    
      }
    },
    async fetchTipTypes() {
      try {
        const response = await axios.get(
          `${process.env.VUE_APP_BASE_URL}tip-types`
        );
        this.tipTypes = response.data.data;
        this.successToast("Tip Categories fetched succesfully")
      } catch (error) {
        this.errorToast(error.message);
      }
    },
    async fetchValidTips() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BASE_URL}tips`);
        this.validTips = response.data.data;
        this.successToast("Valid Tips fetched succesfully")
      } catch (error) {
        this.errorToast(error.message);
      }
    },
    async addShortcode() {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}shortcodes`,
          {
            shortcode: this.shortcodeInput,
          }
        );
        if (response.status === 200) {
            this.shortcodes.push({value:this.shortcodeInput})
          this.successToast(response.data.message)
        } else {
           this.errorToast(error.message)
           
        }
      } catch (error) {
        this.errorToast(error.message)
      }
    },
    async addTipType() {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}tip-types`,
          {
            category: this.tipCategory,
          }
        );
        if (response.status === 200) {
            this.tipTypes.push({ category: this.tipCategory})
          this.successToast(response.data.message);
          
        } else {
          this.errorToast(error.message);
        }
      } catch (error) {
        this.errorToast(error.message);
      }
    },
    async createTip() {
      try {
        const response = await axios.post(
          `${process.env.VUE_APP_BASE_URL}tips`,
          {
            tip: this.tipContent,
            startDate: this.startDate,
            endDate: this.endDate,
            cost: this.cost,
            shortcode: this.selectedShortcode,
            type: this.selectedType,
          }
        );
        if (response.status === 200) {
            this.validTips.push({tip : this.tipContent});
          this.successToast(response.data.message);
        } else {
          this.errorToast(error.message);
        }
      } catch (error) {
        this.errorToast(error.message);
      }
    },
    async deleteShortcode(shortcodeId) {
      try {
        const response = await axios.delete(
          `${process.env.VUE_APP_BASE_URL}shortcodes/${shortcodeId}`
        );
        if (response.status === 200) {
          this.shortcodes = this.shortcodes.filter(
            (shortcode) => shortcode.id !== shortcodeId
          );
          this.successToast(response.data.message);
        } else {
          this.errorToast(error.message);
        }
      } catch (error) {
        this.errorToast(error.message);
      }
    },

    async deleteTip(tipId) {
      try {
        const response = await axios.delete(
          `${process.env.VUE_APP_BASE_URL}tips/${tipId}`
        );
        if (response.status === 200) {
          this.validTips = this.validTips.filter(
            (validTips) => validTips.id !== tipId
          );
          this.successToast(response.data.message);
        } else {
          this.errorToast(error.message);
        }
      } catch (error) {
        this.errorToast(error.message);
      }
    },

    async deleteTipType(tipTypeId) {
      try {
        const response = await axios.delete(
          `${process.env.VUE_APP_BASE_URL}tip-types/${tipTypeId}`
        );

        if (response.status === 200) {
          this.tipTypes = this.tipTypes.filter(
            (tipType) => tipType.id !== tipTypeId
          );
          this.successToast(response.data.message);
        } else {
          this.errorToast(error.message);
        }
      } catch (error) {
        this.errorToast(error.message);
      }
    },

    //success toast notification
    successToast(message) {
      message = String(message);
      this.$refs.toastNotificationsRef.triggerToast("success", message);
    },

    //error toast notification
    errorToast(message) {
      message = String(message); //convert the message to string
      this.$refs.toastNotificationsRef.triggerToast("error", message);
    },
  },
};
</script>

<style scoped>
.bod {
  border: 2px solid green;
}

.btn-custom {
  background-color: #e2725b;
  width: 100%;
}

.btn.btn-custom:hover {
  transform: scale(1);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.list-group-item {
  border-bottom: 1px solid #dee2e6; /* Add border bottom */
}
</style>
