
<template>
<section class="vh-100 loginContainer">
 <div class="container-fluid center-items p-4">
    <div class="row main-content bg-success text-center form-holder">
      <div class="col-md-4 text-center company__info">
        <span class="company__logo"><img src="../assets/images/bt.png"></span>
      </div>
      <div class="col-md-8 col-xs-12 col-sm-12 login_form ">
        <div class="container-fluid mt-4 ">
        
          <div class="row mt-4">
            <form >
                <div class="form-floating mb-3">
                  <input
                    class="form-control"
                    id="inputEmail"
                    type="email"
                    placeholder="Email Address"
                    v-model="email"
                  />
                  <label for="inputUsername">Email</label>
                </div>
                <div class="form-floating mb-3">
                  <input
                    class="form-control"
                    id="inputPassword"
                    type="password"
                    placeholder="Password"
                    v-model="password"
                  />
                  <label for="inputPassword">Password</label>
                </div>
                
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-between
                    mt-4
                    mb-0
                  "
                >
                  <a class="btn login-btn text-white" href="#" @click="submitForm()"
                    >Login</a
                  >
                </div>
              </form>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</section>

</template>

<script>

import axios from "axios";
import { ref,inject } from 'vue'
import store from '../store'

export default {
  setup() {
    const email = ref("");
    const password = ref("");
    const token = ref("null");

    const router = inject('router');

    const submitForm = async () => {
      if (!email.value || !password.value) {
        alert("Form Validation Failure");
        return;
      }
      try {
        const { data } = await axios.post(`${process.env.VUE_APP_BASE_URL}login`, {
          email: email.value,
          password: password.value,
        });
        store.dispatch('setToken', data.token);
        store.dispatch('setEmail', email.value);
        store.dispatch('setPassword', password.value);

     
        router.push({ name: "Dashboard" });
      } catch (error) {
        console.error(error);
      }
    };

    return {
      email,
      password,
      submitForm,
      token
    };
  },
}


</script>


<style scoped>

.loginContainer{
  background-image: linear-gradient(to right, #495159, #2e3439);
}


.login-btn{
  background-color: #495159;
  width:100%;

}

.btn.login-btn:hover {

  transform: scale(1.0);
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.5);
  cursor: pointer;
}


img{

  height: 100px;
  
}

.form-holder{
  height:250px;
 
}

.main-content{
	width: 50%;
	border-radius: 20px;
	box-shadow: 0 5px 5px rgba(0,0,0,.4);
	margin: 5em auto;
	display: flex;
}
.company__info{
	background-color: #495159;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: #fff;
}

@media screen and (max-width: 640px) {
	.main-content{width: 90%;}
	.company__info{
		display: none;
	}
	.login_form{
		border-top-left-radius:20px;
		border-bottom-left-radius:20px;
	}
}
@media screen and (min-width: 642px) and (max-width:800px){
	.main-content{width: 70%;}
}
.row > h2{
	color:#000000;
}
.login_form{
	background-color: #fff;
	border-top-right-radius:20px;
	border-bottom-right-radius:20px;
	border-top:1px solid #ccc;
	border-right:1px solid #ccc;
}
form{
	padding: 0 2em;
}
.form__input{
	width: 100%;
	border:0px solid transparent;
	border-radius: 0;
	border-bottom: 1px solid #aaa;
	padding: 1em .5em .5em;
	padding-left: 2em;
	outline:none;
	margin:1.5em auto;
	transition: all .5s ease;
}
.form__input:focus{
	border-bottom-color: #000000;
	box-shadow: 0 0 5px rgba(0,80,80,.4); 
	border-radius: 4px;
}

</style>
