import { createApp } from 'vue';
import App from './App.vue'
import router from './router'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faBars} from "@fortawesome/free-solid-svg-icons";
import { faHouseUser} from "@fortawesome/free-solid-svg-icons";
import { faCircleCheck} from "@fortawesome/free-solid-svg-icons";
import { faCircleExclamation} from "@fortawesome/free-solid-svg-icons";
import { faGear} from "@fortawesome/free-solid-svg-icons";
import { faUser} from "@fortawesome/free-solid-svg-icons";
import { faChartSimple} from "@fortawesome/free-solid-svg-icons";
import { faSackDollar} from "@fortawesome/free-solid-svg-icons";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faPercent  } from "@fortawesome/free-solid-svg-icons";
import {faWifi} from "@fortawesome/free-solid-svg-icons";
import { faFutbol } from "@fortawesome/free-solid-svg-icons";
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import store from './store/index';
import Notifications from '@kyvg/vue3-notification'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';








library.add(faWifi,faFutbol,faBars,faHouseUser,faCircleCheck,faCircleExclamation,faGear,faUser,faChartSimple,faSackDollar,faMessage,faPaperPlane,faPercent);


createApp(App).use(router).use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 7,
    newestOnTop: true
}).use(Notifications).use(store).provide('router', router).component('EasyDataTable', Vue3EasyDataTable).component("font-awesome-icon", FontAwesomeIcon).mount('#app')
