<template>
    <div></div>
  </template>
  
  <script>
  import { useToast } from "vue-toastification";
  
  export default {
    setup() {
      const toast = useToast();
      return { toast };
    },
    methods: {
      triggerToast(type, message) {
        this.toast[type](message, {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
    },
  };
  </script>
  
  <style>
  </style>