<template>

   <!--Toast Alert Component is imported here-->
   <ToastNotifications ref="toastNotificationsRef" />

  <div class="row">
    <div class="col-12">
      <div class="container-fluid discount-card">
        <div class="card">
          <div class="card-shadow">
            <h5 class="">Current Discount</h5>
            <h6>Resets every 12 Hrs</h6>
          </div>
          <div
            class="card-body d-flex justify-content-center align-items-center"
          >
            <p class="discount">{{ currentDiscount }}%</p>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <button class="btn btn-outline-danger" @click="resetDiscount">
              Reset Discount
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="container-fluid password-reset-card">
        <div class="card shadow mt-5">
          <div class="card-body">
            <h5 class="card-title text-center">Set Discount</h5>
            <form class="" @submit.prevent="submitForm">
              <div class="form-group p-2">
                <label for="discount">Discount (%):</label>
                <input
                  type="number"
                  class="form-control"
                  id="discount"
                  placeholder="Enter discount percentage"
                  v-model="discount"
                />
              </div>
              <div class="form-group p-2">
                <label for="startTime">Start Time:</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  id="startTime"
                  v-model="startTime"
                />
              </div>
              <div class="form-group p-2">
                <label for="endTime">End Time:</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  id="endTime"
                  v-model="endTime"
                />
              </div>
              <button type="submit" class="btn login-btn text-white mt-3">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ToastNotifications from "@/components/ToastNotifications.vue";

export default {
  components: { ToastNotifications },

  data() {
    return {
      currentDiscount: null,
      discount: "",
      startTime: "",
      endTime: "",
    };
  },

  created() {
    this.getDiscount();
  },
  methods: {
    submitForm() {
      const requestBody = {
        discount: this.discount,
        start_time: this.startTime,
        expiry_time: this.endTime,
      };

      axios
        .post(`${process.env.VUE_APP_BASE_URL}set-discount`, requestBody)
        .then((response) => {

          this.currentDiscount = response.data.data
      
          this.successToast(`${this.discount}% discount set succesfully`)

          this.startTime ="";
          this.endTime = "";
          this.discount = "";
         

        })
        .catch((error) => {
          this.errorToast(error.message)
          this.startTime ="";
          this.endTime = "";
          this.discount = null;
        });
    },

    async getDiscount() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}check-discount-validity`)
        .then((response) => {
          this.currentDiscount = response.data;
          this.successToast('Fetch successful')
        })
        .catch((error) => {
          this.errorToast(error.message)
        });
    },

    async resetDiscount() {
      axios
        .get(`${process.env.VUE_APP_BASE_URL}reset-discount-cache`)
        .then((response) => {
          this.successToast(response.data.message);
          this.currentDiscount = null
          
        })
        .catch((error) => {
          this.errorToast(error.message)
        });
    },

     //success toast notification
     successToast(message) {
      message = String(message);
      this.$refs.toastNotificationsRef.triggerToast("success", message);
    },

    //error toast notification
    errorToast(message) {
      message = String(message); //convert the message to string
      this.$refs.toastNotificationsRef.triggerToast("error", message);
    },
  },
};
</script>

<style scoped>
h1 {
  font-family: "Roboto Mono", monospace;
  color: #454545;
}

.login-btn {
  background-color: #e2725b;
  width: 100%;
}

.btn.login-btn:hover {
  transform: scale(1);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.password-reset-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 323px;
}

.discount-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

.discount {
  font-weight: bold;
  font-size: 50px;
}

.border {
  border: 2px solid red;
}
</style>
