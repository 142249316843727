<template>
  <div class="chart-container">
    <canvas id="myChart" class="canvas"></canvas>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      myData: [],
      xValues: ["Mon", "Tue", "Wed", "Thurs", "Friday","Saturday","Sunday"],
      yValues: [10, 20, 30, 40, 50,100,150],
      barColors: ["#495159","#495159","#495159","#495159","#495159","#495159","#495159"]
    }
  },

  mounted(){
this.getData();
  },
  methods: {
    async getData() {
  try {
    const response = await axios.get(`${process.env.VUE_APP_BASE_URL}datecount`)
    let temp = {}
    response.data.map(item => {
      temp[item.day] = item.count
    })
    let days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    this.myData = days.map(day => temp[day] ? temp[day] : 0)
    this.yValues = this.myData;
    this.createChart();
    return this.myData
  } catch (error) {
    console.error(error)
  }
},
    createChart(){
      new Chart("myChart", {
        type: "bar",
        data: {
          labels: this.xValues,
          datasets: [{
            backgroundColor: this.barColors,
            data: this.yValues
          }]
        },
        options: {
          legend: {display: false},
           scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true, // set the minimum value of y-axis to zero
                        suggestedMax: 10, // set the maximum value of y-axis to 10
                      },
                    },
                  ],
                },
          title: {
            display: true,
            text: "Weekly Airtime Disbursement"
          }
        }
      });
    }
  }
}


</script>

<style scoped>

.chart-container{

    width: 700px;
 
   max-width: 100%;
    
}




</style>
